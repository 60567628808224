import React from 'react';
import Container from 'container';
import ButtonV2 from 'button-v2';
import Headline from 'headline';
import s from './blog-banner-b.module.scss';

export default function CTABanner({
  text,
  headline,
  subtitle,
  button,
  secondary,
  ...props
}) {
  const large = text && text.length > 30;
  const shortHeadline = headline && headline.length < 12;
  return (
    <Container className={`${s.root} ${secondary ? s.secondary : ''}`}>
      <div className={s.wrapper}>
        <div className={s.normalWrapper}>
          <Headline
            h2
            subtitle
            className={`${s.normal} ${s.first} ${large ? s.large : ''}`}
            html={text}
          />
        </div>
        <div className={s.normalWrapper}>
          <Headline h2 subtitle className={`${s.normal} ${s.morePadding}`}>
            {subtitle}{' '}
            <Headline
              h1
              wild
              className={`${s.wild} ${shortHeadline ? s.shortHeadline : ''}`}
              html={headline}
            />
          </Headline>
        </div>
        <ButtonV2
          tertiary
          wrapperClass={s.buttonWrapper}
          buttonClass={s.button}
          {...{ button, ...props }}
        >
          <Headline h3 wildExtra className={s.arrow} html="L" />
        </ButtonV2>
      </div>
    </Container>
  );
}
