const fetchWordpress = require('../api/wordpress');
const fetchWordpressOld = require('../api/wordpress-old');

async function fetchWordpressByPage(type, segment, offset, data = [], idx = 1) {
  try {
    const query = `/${type}${type.includes('?') ? '&' : '?'}per_page=${segment}&order=asc${
      offset ? `&offset=${offset}` : ''
    }`;
    const fetchData = await fetchWordpress(query);
    if (fetchData[0]) {
      const accumulatedData = [...data, { page: idx, data: fetchData }];
      return await fetchWordpressByPage(
        type,
        segment,
        offset ? offset + segment : segment,
        accumulatedData,
        idx + 1
      );
    }
    return data;
  } catch (e) {
    throw e;
  }
}

async function fetchAllWordpress(type, offset, data = []) {
  try {
    const query = `/${type}${type.includes('?') ? '&' : '?'}per_page=100&order=asc${
      offset ? `&offset=${offset}` : ''
    }`;
    const fetchData = await fetchWordpress(query);
    if (fetchData[0] && type !== 'categories') {
      const accumulatedData = [...data, ...fetchData];
      return await fetchAllWordpress(
        type,
        offset ? offset + 100 : 100,
        accumulatedData
      );
    }
    return type !== 'categories' ? data : fetchData;
  } catch (e) {
    throw e;
  }
}

async function fetchWordpressOldByPage(type, segment, offset, data = [], idx = 1) {
  try {
    const query = `/${type}${type.includes('?') ? '&' : '?'}per_page=${segment}&order=asc${
      offset ? `&offset=${offset}` : ''
    }`;
    const fetchData = await fetchWordpressOld(query);
    if (fetchData[0]) {
      const accumulatedData = [...data, { page: idx, data: fetchData }];
      return await fetchWordpressOldByPage(
        type,
        segment,
        offset ? offset + segment : segment,
        accumulatedData,
        idx + 1
      );
    }
    return data;
  } catch (e) {
    throw e;
  }
}

async function fetchAllWordpressOld(type, offset, data = []) {
  try {
    const query = `/${type}${type.includes('?') ? '&' : '?'}per_page=100&order=asc${
      offset ? `&offset=${offset}` : ''
    }`;
    const fetchData = await fetchWordpressOld(query);
    if (fetchData[0]) {
      const accumulatedData = [...data, ...fetchData];
      return await fetchAllWordpressOld(
        type,
        offset ? offset + 100 : 100,
        accumulatedData
      );
    }
    return data;
  } catch (e) {
    throw e;
  }
}

module.exports = {
  fetchWordpressByPage: async (type, segment) => fetchWordpressByPage(type, segment),
  fetchAllWordpress: async type => fetchAllWordpress(type),
  fetchWordpressOldByPage: async (type, segment) => fetchWordpressOldByPage(type, segment),
  fetchAllWordpressOld: async type => fetchAllWordpressOld(type),
};
