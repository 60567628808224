module.exports = function keyToKey(obj) {
  const keys = obj && Object.keys(obj).reduce((acc, curr) => {
    const isArray = Array.isArray(obj[curr]);
    const isObject = typeof obj[curr] === 'object' && !isArray;
    const isImage = !!(isObject && obj[curr] && obj[curr].mime_type);
    const newArray = isArray && obj[curr].map(val => {
      const valIsObject = typeof val === 'object' && !Array.isArray(val);
      return valIsObject ? keyToKey(val) : val;
    });
    const Image = isImage && obj[curr];
    const newObject = isObject && keyToKey(obj[curr]);
    const value = newArray || Image || newObject || curr;
    return {
      ...acc,
      [curr]: value
    };
  }, {});
  return keys;
};
