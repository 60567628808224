import React from 'react';
import Button from 'button';
import PopupGenerator from 'components/popup-generator';
import { usePopup } from '@/hooks/usePopup';

// PopupTrigger is a button with a wrapper, that triggers the popup if passed as prop, if not, renders a button, link or a tag depending on data passed
export default function PopupTrigger({
  button,
  wrapperClass,
  buttonClass,
  primary,
  secondary,
  tertiary,
  children,
  ...props
}) {
  const { popup } = usePopup();
  return (
    <>
      {
        <>
          <div className={wrapperClass || ''}>
            <Button
              {...{
                primary,
                secondary,
                tertiary
              }}
              className={buttonClass || ''}
              href={button.href}
              onClick={props.popups ? () => popup.show() : false}
            >
              {children}
              {button.text}
            </Button>
          </div>
          {props.popups && <PopupGenerator {...{ popup, ...props }} />}
        </>
      }
    </>
  );
}
