import React, { useContext } from 'react';
import Img from 'img';
import Container from 'container';
import Headline from 'headline';
import getTag from 'utils/get-tag';
import Button from 'button';
import validate from 'utils/validate';
import { navigate } from 'gatsby';
import { useFormInput, useErrors, useSubmitReady } from '@/hooks/useForm';
import { useSubmitState } from '@/hooks/useSubmitState';
import Spinner from 'shared/spinner';
import { lpContext } from 'context/lp-context';
import axios from 'axios';
import s from './mailing-a.module.scss';

export default function MailingAPopup({
  image,
  title_line1,
  title_line2,
  wild_title,
  text,
  button_text,
  type,
  thankyou_slug,
  ...props
}) {
  const lp_options = useContext(lpContext);
  console.log(lp_options, 'context');
  // Tags
  const TextTag = getTag(text);

  // Inputs
  const name = useFormInput('');
  const email = useFormInput('');

  // Error handlers
  const emailErrors = useErrors({ email: email.state }, validate);
  const nameErrors = useErrors({ name: name.state }, validate);

  // Sumbit handlers
  const submitReady = useSubmitReady(
    {
      name: name.state,
      email: email.state
    },
    validate
  );
  const [disable, submit, submitHandler] = useSubmitState(
    handleSubmit,
    button_text,
    Spinner
  );

  function handleSubmit() {
    const isLP = lp_options !== undefined && lp_options.enable; // Check if this page has LP options
    const payload = {
      name: name.state.value,
      email: email.state.value,
      type: isLP ? 'lp' : type // A flag for the backend, DON"T REMOVE THIS
    };
    if (isLP) {
      payload.campaign = lp_options.campaign; // Add Lp campaign
    }
    console.log(payload, 'payload');
    axios(
      'https://us-central1-laurie-anne-king.cloudfunctions.net/handleFormSubmission',
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        data: payload
      }
    )
      .then(x => {
        console.log(x);
        navigate(isLP ? lp_options.thankyou_slug : thankyou_slug || '/');
      })
      .catch(err => console.log(err));
  }
  return (
    <Container className={s.root}>
      <div className={s.wrapper}>
        <div className={s.imgWrapper}>
          <Img className={s.img} src={image.title} alt={image.title} />
        </div>
        <div className={s.content}>
          <div className={s.titleWrapper}>
            <Headline subtitle h2 className={s.title}>
              {title_line1}
            </Headline>
            <Headline subtitle h2 className={s.title}>
              {title_line2}
            </Headline>
            <Headline subtitle wild className={s.wild}>
              {wild_title}
            </Headline>
          </div>
          <form className={`${s.form}`}>
            <input
              type="text"
              name="name"
              placeholder="FULL NAME *"
              value={name.state.value}
              {...name.events}
              className={`${s.input} ${nameErrors.name && 'error'}`}
            />
            <input
              type="text"
              name="email"
              placeholder="EMAIL ADDRESS *"
              value={email.state.value}
              {...email.events}
              className={`${s.input} ${emailErrors.email && 'error'}`}
            />
            <TextTag className={`italic ${s.text}`}>{nameErrors.name || emailErrors.email || null}</TextTag>

            <div className={s.buttonWrapper}>
              <Button
                tertiary
                className={s.button}
                disabled={!submitReady || disable}
                onClick={submitHandler}
              >
                {submit}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
}
