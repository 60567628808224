const keyToKey = require('./key-to-key');

function blogData(page) {
  if (!page) return {};
  const { acf, slug, id, date, title, categories } = page;
  const templates = Object.keys(acf)
    .filter(type => Array.isArray(acf[type]))
    .map(template => acf[template])
    .reduce((acc, template) => (template ? [...acc, ...template] : acc), []);
  const acfKeys = templates && process.env.GATSBY_DEVELOPMENT && templates.map(item => keyToKey(item));
  return {
    slug,
    id,
    blog: true,
    excerpt: acf.excerpt,
    postCategories: categories,
    mainImage: acf.main_image,
    date,
    title,
    meta: {
      title: acf.title,
      description: acf.description,
      ogtitle: acf.ogtitle,
      ogdescription: acf.ogdescription,
      ogurl: acf.ogurl,
      ogimage: acf.ogimage,
    },
    templates,
    acfKeys
  };
}

function pageData(page) {
  if (!page) return {};
  const { acf, id } = page;
  const templates = Object.keys(acf)
    .filter(type => Array.isArray(acf[type]))
    .map(template => acf[template])
    .reduce((acc, template) => (template ? [...acc, ...template] : acc), []);
  const acfKeys = templates && process.env.GATSBY_DEVELOPMENT && templates.map(item => keyToKey(item));
  return {
    slug: acf.slug,
    id,
    meta: {
      title: acf.title,
      description: acf.description,
      ogtitle: acf.ogtitle,
      ogdescription: acf.ogdescription,
      ogurl: acf.ogurl,
      ogimage: acf.ogimage,
    },
    templates,
    acfKeys,
    lp_options: acf.lp_options
  };
}

module.exports = {
  blogData,
  pageData
};
